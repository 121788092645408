.contact-section {
    padding: 4rem 2rem;
    max-width: 1100px;
    margin: auto;
}

.contact-header {
    font-weight: 100;
    font-size: 1.2rem;
    padding-bottom: 2rem;
}

.contact-form {
    padding: 2rem 2rem;
    margin: 0 4rem;
    display: grid;
    grid-template-areas:
        "top top"
        "name email"
        "subject subject"
        "message message"
        "button button";
    row-gap: 20px;
    column-gap: 25px;
    background-color: rgb(253, 253, 253);/* Light gray background */
    border-radius: 30px;
    border: #d8d8d8 solid 2px;
}

.contact-form-sent {
    padding: 2rem 2rem;
    margin: 0 4rem;
    background-color: rgb(253, 253, 253);/* Light gray background */
    border-radius: 30px;
    border: #d8d8d8 solid 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
}

@media (max-width: 768px) {
    .contact-header {
        padding: 0 0 2rem 2rem;
    }

    .contact-form {
        margin: 0;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        border-radius: 0;
        border-left: none;
        border-right: none;
    }
    .contact-section {
        padding: 0;
    }
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form-group input,
.form-group textarea {
    border: 1px solid #d8d8d8;
    transition: all 0.1s ease-in;
    padding: 11px 20px;
    font-size: 1rem;
    letter-spacing: 1px;
    width: 100%;
    background-color: #ffffff;
    /* White background for inputs */
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

#title {
    grid-area: top;
    font-weight: 300;
    font-size: 2rem;
}

#message {
    resize: none;
    height: 6rem;
}

#contact-name {
    grid-area: name;
}

#contact-email {
    grid-area: email;
}

#contact-subject {
    grid-area: subject;
}

#contact-message {
    grid-area: message;
}

#send-button {
    grid-area: button;
    cursor: pointer;
    padding: 14px 40px;
    font-size: 0.9rem;
    border: none;
    background-color: #1babff;
    color: white;
    letter-spacing: 1px;
    border-radius: 40px;
    width: 140px;
    transition: all 0.3s ease-in;
    margin: auto;
}

