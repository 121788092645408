.bio-section {
    display: grid;
    grid-template-areas:
        "header header"
        "headshot bio";
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 5fr;
    border-color: black solid 2px;
    padding: 4rem 2rem;
    max-width: 1100px;
    margin: auto;

}

.bio-header {
    grid-area: header;
    font-weight: 100;
    font-size: 1.2rem;
    height: 100%;
}

.left-side {
    grid-area: headshot;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    padding-right: 1rem;
}

#image-container {
    border-radius: 20px;
    overflow: hidden;
    max-height: 90%;
    max-width: 90%;
}

#headshot {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    height: 85%;
}


.bio {
    grid-area: bio;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.75;
    display: flex;
    flex-direction: column;

}

.skills {
    font-weight: 400;
    word-wrap: break-word;
    color: rgb(18, 30, 39);
    flex-wrap: wrap;
    white-space: nowrap;
    width: 100%;
    max-width: 100%;
    display: flex;
}

.bio-skill-box {
    font-size: 1rem;
    word-wrap: break-word;
    white-space: nowrap;
    background: rgb(18, 30, 39);
    color: rgb(255, 255, 255);
    padding: 0.25rem 0.7rem;
    margin: 0px 0.25rem 1rem 0px;
    font-weight: 700;
    text-align: center;
    display: flex;
    font-size: 1rem;
}

#bio-text-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 2em;
}

#bio-text-container b {
    font-weight: 550;
}

#about-page-link {
    color: #1babff;
    text-decoration: none;
    transition: .3s ease-in-out;
}

#about-page-link:hover {
    color: #1570a4;
}


@media (max-width: 768px) {
    .bio-section {
        display: flex;
        flex-direction: column;
        padding: 2rem 2rem;
        gap: 20px;

    }

    .left-side {
        padding-top: 20px;
        padding-right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 60%;
    }

    .skills {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #bio-text-container {
        padding-top: 2rem;
    }

    #about-text {
        padding-top: 1rem;
    }

    #about-page-link {
        padding-top: 1rem;
    }
}