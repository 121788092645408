#opening {
    background-color: #051924;
    color: white;
    min-height: 87.5vh;
    padding: 20vh 15vh 20vh;
}

.opening-item {
    word-wrap: break-word;
    box-sizing: inherit;
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    color: rgb(255, 255, 255);
    max-width: 60rem;
}

#tagline {
    font-size: 4rem;
}

.social-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: .5rem;
    gap: 8px 0px;
}

.social-icons-small {
    display: flex;
}

.social-icons a {
    display: flex;
    flex-direction: column;
    color: #1babff;
    font-size: 48px;
    text-decoration: none;
    padding-right: 1.5rem;
    transition: color 0.3s ease-in-out;
}


.social-icons a:hover{
    color: #0089d9;
}


.resume-button {
    color: #051924;
    text-decoration: none;
    background-color: #1babff;
    font-size: 32px;
    border-radius: 32px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    transition: background-color 0.3s ease-in-out;
    font-weight: 400;
}

.resume-button:hover {
    background-color: #0089d9;
    color: #051924;;
}

.highlighted-text {
    background: linear-gradient(to right, transparent 0%, transparent 50%, #0089d9 50%, #25b15b 100%);
    background-size: 200% 100%;
    background-position: 100% 0;
    transition: background-position 0.3s ease-in-out;
}

.highlighted-text:hover {
    background-position: 0 0;
}

.opening-description {
    word-wrap: break-word;
    margin-bottom: 1.75rem;
    font-weight: 300;
    color: rgb(199, 199, 199);
    font-size: 1.2rem;
    max-width: 45rem;
    margin-bottom: 1.75rem;
    line-height: 1.75;
    
}

.opening-item b {
    display: contents;
}

/* Media Queries */


@media (max-width: 768px) {
    #opening { 
        padding: 10vh 5vh; 
    }

    #tagline {
        font-size: 3rem;
        
    }

    .social-icons a {
        font-size: 36px;
    }

    .opening-description {
        font-size: 1rem;
    }
}
@media (max-width: 912px) and (min-width: 769px) {
    #opening {
        padding: 20vh 10vh 20vh;
    }
}