*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.highlight {
  position: relative;
  display: inline-block;
  font-weight: 400;
}

.highlight div:nth-child(2) {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(20%) translateX(.30rem);
  width: 100%;
  height: 40%;
  transition: width 0.4s ease-in-out 0s;
  background: #1babff;
  z-index: -1;
}

.highlight:hover div:nth-child(2) {
  width: 0%;
}