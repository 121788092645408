.about-page-container {
    max-width: 1100px;
    margin: auto;
    padding: 0 2rem;
    min-height: 70vh;

}

.about-page-container p {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.75;
    margin-bottom: 1.75rem;
}

.about-page-container strong {
    font-weight: 500;
}

.about-page-container h1 {
    font-weight: 400;
}

.about-page-container h1,
.about-page-container h2 {
    margin-top: 2rem;
    margin-bottom: 1.75rem;
}

@media only screen and (min-width: 768px) {
    .about-page-container h1 {
        font-size: 4rem;
    }
}