.project-page-container {
    max-width: 1100px;
    padding: 2rem 4rem;
    margin: auto;
}

.project-page-container p {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.75;
    margin-bottom: 1.75rem;
}

.project-page-container strong {
    font-weight: 500;
}

.project-page-container h1 {
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 1.1;
    margin-top: 3.5rem;
    margin-bottom: 1.75rem;
}

.project-intro-container {
    line-height: 1.75;
    font-weight: 300;

}

.intro-project-details {
    padding-bottom: 2rem;
    display: flex;
    justify-content: space-around;
    flex-flow: column;
}

@media only screen and (min-width: 768px) {
    .intro-project-details {
        flex-flow: row;
    }
}

.intro-project-details div {
    width: 100%;
    text-align: left;
}

.intro-project-details div h3 {
    margin: 1rem 0;
    font-size: 1.5rem;
}

.intro-project-details div div {
    font-size: 1rem;
}

a {
    color: #1babff;
    font-weight: bold;
    transition: filter 0.4s ease-in-out 0s;
    text-decoration: none;
}

a:hover {
    color: #1570a4;
}

.image-container {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }
  
  .project-images {
    max-width: 100%;
    max-height: 500px;
    object-fit: scale-down;
    box-shadow: 1px 1px 20px 5px rgba(0, 0, 0, 0.3);
    border: 1.5px solid rgb(181, 181, 181);
  }