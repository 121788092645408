nav { /* Navigation bar */
    background-color: #051924;
    padding: 20px 80px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
    width: 100%;
  }
  
  #home-link { /* 'Steven Vasquez' Link to home page */
    margin-bottom: 20px;
    text-align: center;
  }
  
  #nav-bar-links { /* Navigation bar links */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #nav-bar-links li {
    list-style: none;
    padding: 0 10%;
    position: relative;
  }
  
  #nav-bar-links li a,
  #home-link a {
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 600;
    color: white;
    position: relative;
  }
  
  #nav-bar-links li a:hover {
    color: #c0c7cc;
    cursor: pointer;
  }

  #nav-bar-links li a::after {
    content: "";
    width: 25px;
    height: 4px;
    background-color: #1babff;
    position: absolute;
    bottom: -4px;
    left: 0;
    transition: width 0.3s ease-in-out;
  }
  
  #nav-bar-links li a:hover::after,
  #nav-bar-links li a.active::after {
    width: 100%;
  }
  
/* Styles for small screens */
#mobile { /* Hamburber menu not displayed*/
  display: none;
}

#mobile i{
    color: white;
    align-items: center;
}

@media screen and (max-width: 769px) { /* Media query for small screens */
  #nav-bar-links { /* Navigation bar links */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 68px;
    right: -300px;
    width: 300px;
    height: 100vh;
    background-color: #40484d;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 40px 0 0 10px;
    transition: 0.3s ease-in-out;
  } 
  #nav-bar-links.active {
    right: 0px;
  }
  #nav-bar-links li {
    padding: 10px 0;
  }
  #nav-bar-links {
    padding-top: 0px;
  }
  #mobile { /* Hamburber menu */
    display: block;
    position: absolute;
    top: 22px;
    right: 50px;
  }
  #mobile i {
    font-size: 25px;
    cursor: pointer;
  }
  #home-link { /* 'Steven Vasquez' Link to home page */
    margin-bottom: 0px;
    text-align: center;
    display: flex;
    align-items: center; 
    justify-content: center;
  }
  nav { /* Navigation bar */
    height: 68px;
    background-color: #051924;
    padding: 20px 20px 20px 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
  }
}