.carousel-container {
    max-width: 800px;
    max-height: 650px;
    margin: 0 auto;
}

.carousel-display img {
    max-width: calc(100% - 94px);
    max-height: 400px;
    object-fit: contain;
}

.carousel.carousel-slider .control-arrow {
    background-color: rgb(163, 163, 163);
    width: 42px;

}

.carousel .control-disabled.control-arrow {
    display: block;
    opacity: .4;
}

.carousel-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.carousel-display:hover {
    cursor: pointer;
}

@media (max-width: 769px) {


    .carousel-caption {
        font-size: .6rem !important;
    }
}


.carousel.carousel-slider .control-arrow.active {
    opacity: 1 !important;
}

.carousel .control-arrow:focus {
    opacity: none;
    filter: none;
}

.carousel.carousel-slider .control-arrow:hover {
    background: rgb(163, 163, 163);
}

.carousel .control-arrow:focus {
    opacity: .4;
    filter: alpha(opacity=100);
}

.carousel .control-arrow:hover {
    opacity: 1 !important;
    filter: alpha(opacity=100);
}

.carousel .carousel-status {
    top: 0px;
    right: 60px;
    color: #000;
    font-size: 1.4rem;
    text-shadow: none;
    margin-top: 1rem;
    text-transform: uppercase;
    font-weight: 200;
}

.carousel .control-arrow.control-prev {
    display: block !important;
}

.carousel-caption {
    word-wrap: break-word;
    max-width: calc(100% - 94px);
    margin-top: 5px;
    padding: 5px;
    background-color: #bebebe47;
    display: inline-block;
    text-align: center;
}