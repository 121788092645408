.footer-container {
  background-color: #051924;

  width: 100%;
  line-height: 1.75;
  font-weight: 400;
  word-wrap: break-word;
  padding: 2rem;
}

.footer-flexbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0px 2rem;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.site-info .name-link {
  color: white;
  font-size: 1.4rem;
  line-height: 2rem !important;
  margin-bottom: 1rem !important;
  transition: color 0.3s ease-in-out;
}

.site-info .name-link:hover {
  cursor: pointer;
  color: rgb(234, 234, 234);
}

.site-info div,
.site-info div a {
  font-size: 0.8rem;
  word-wrap: break-word;
  color: rgb(199, 199, 199);
  padding-top: .5rem;
  transition: color 0.3s ease-in-out;
}

.site-info div a:hover {
  color: rgb(234, 234, 234);
}


.footer-site-links {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.footer-site-links a {
  transition: color 0.2s ease-in-out;
}

.footer-site-links a:hover {
  cursor: pointer;
}

.contact-links,
.site-links,
.site-info {
  width: 200px;
}

.footer-social-icons {
  display: flex;
  flex-direction: row;
  font-size: 32px;
  text-decoration: none;
  padding-right: 1.5rem;
  transition: color 0.3s ease-in-out;
  margin-top: -4px;
}

.footer-social-icons a {
  padding-right: 16px;
  color: rgb(234, 234, 234);
  transition: color 0.3s ease-in-out;
}

.footer-social-icons a:hover {
  color: white;
}


.footer-header {
  color: rgb(199, 199, 199);
  font-weight: 600;
  margin-bottom: .5rem;
}